import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Optional: this is for global CSS styles
import App from './App.jsx';
import Paricles from './Paricles.jsx';




ReactDOM.render(
  <React.StrictMode>
    <Paricles/>

    <App />
    
  </React.StrictMode>,
  document.getElementById('root') // Make sure this matches the ID in your HTML
);


function index() {
  return (
    
    <div>index</div>
  )
}

export default index
