import React from 'react';
import './index.css'; 

function App() {
  return (
    
    <div className="flex flex-col items-center justify-center min-h-screen text-center mx-auto">
     
      <div className='m-2'>
        <h1 className='text-3xl lg:text-7xl p-4'>Hetki Company</h1>
        <div>
          <h2 className='text-xl lg:text-2xl italic'>we're updating our websites</h2>
        </div>
        <div className='pt-8'>
          <h3 className='text-xl lg:text-2xl font-light italic'>contact us:</h3>
          <div className='flex-row-reverse justify-between text-xl lg:text-2xl'>
          <h3 href="mailto:info@hetkicompany.com" className="btn-mailto underline p-2 text-xl lg:text-2xl font-light">
            info@hetkicompany.com
          </h3>
            <h3 className='underline p-2 text-xl font-light'>+358 40 1413605</h3>
            
          </div>
        </div>
      </div>
      
 
    </div>
  );
}

export default App;